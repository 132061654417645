<template>
    <div v-if="screenWidth <= 800 ? true : false ">
        <el-card style="margin-bottom: 10px; height: 60px;">
            <div style="display: inline-block; float: left; ">
                <el-space wrap>
                    <el-dropdown>
                        <el-button size="small">
                            {{tabname}}
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="(tabsitem, tabsindex) in tabsName"
                                    @click="handleClick(tabsitem)">{{ tabsitem }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <slot name="search-r"></slot>
                </el-space>
            </div>
            <div style="display: inline-block; float: right; ">
                <slot name="search-l"></slot>
            </div>
        </el-card>
        <slot name="content"></slot>
    </div>
    <el-card class="box-card-b" shadow="never" v-else>
        <el-container>
            <el-aside :width="isCollapse ?'65px':'200px'" class="hidden-xs-only">
                <el-row justify="start">
                    <el-col>
                        <el-tooltip :content="isCollapse?'打开 侧栏':'关闭 侧栏'" placement="top" effect="light">
                            <el-switch v-model="isCollapse"
                                style="--el-switch-on-color: #909399; --el-switch-off-color: #c6e2ff" size="small" />
                        </el-tooltip>
                    </el-col>
                </el-row>
                <el-row>
                    <el-scrollbar :height="innerHeight <= 400 ? 400 : innerHeight- 175">
                        <el-col>

                            <el-menu @select="handleClick" :default-active="tabname" :collapse="isCollapse"
                                :collapse-transition='false'>
                                <el-menu-item v-for="(tabsitem, tabsindex) in tabsName" :key="tabsindex"
                                    :index="tabsitem">
                                    <el-icon>
                                        <component is="Files" style="width: 20px; height:20px;" />
                                    </el-icon>
                                    <template #title> {{ tabsitem }}</template>
                                </el-menu-item>
                            </el-menu>

                        </el-col>
                    </el-scrollbar>
                </el-row>
            </el-aside>
            <el-main>
                <div style="display: inline-block; float: left; ">
                    <el-space wrap>
                        <slot name="search-r"></slot>
                        <span style="color: thistle;">|</span>
                        <slot name="search"></slot>
                    </el-space>
                </div>
                <div style="display: inline-block; float: right; ">
                    <slot name="search-l"></slot>
                </div>
                <slot name="content"></slot>
            </el-main>
        </el-container>
    </el-card>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        name: "CatTabs",
        props: {
            tabsName: Array,
            tabname: String
        },
        computed: {
            // Vue计算属性
            ...mapState(['screenWidth', 'innerHeight']),
        },
        data() {
            return {
                isCollapse: false
            }
        },
        watch: {
            screenWidth: function(n, o) {
                this.isCollapse = n < 1000 ? true : false
            },
        },
        emits: ['tabClick'],
        methods: {
            handleClick(v) {
                //将数据传递给父组件
                this.$emit('tabClick', v)
            },
        },
    }
</script>

<style scoped>
    .el-main {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;
    }

    .el-menu-item {
        height: 2.8rem;
    }
</style>