export function tableHeight(obj) {
    return new Promise(resolve => {
        // var height = window.innerHeight - obj.$refs.table1.$el.offsetTop - 52 - 80 - 50
        var height = window.innerHeight - 52 - 82
        resolve(height)
    })
}
export function geTaday(obj) {
    return new Promise(resolve => {
        let date = new Date()
        let year = date.getFullYear()
        const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        resolve(`${year}/${month}/${day}`)
    })
}

export function getabs() {
    return new Promise(resolve => {
        //获取侧边列表
        let acvtiveHPath = window.location.hash.replace('#', "").replace('/', "")
        let route = JSON.parse(window.localStorage.getItem('route'))
        route.forEach(v => {
            if (v.path == acvtiveHPath) {
                resolve(v.tabsName)
            }
        })
    })
}

export function createOption(v) {
    return new Promise(resolve => {
        //获取侧边列表
        let readModify = []
        for (let key in v['readModify']) {
            readModify.push({
                value: key,
                label: readModify[key],
            })
        }
        let option = []
        v['option'].forEach((v, k) => {
            option.push({
                value: k,
                label: v,
                children: option
            })
        })
        resolve(option)
        return option
    })
}