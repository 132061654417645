<template>
    <div v-if="screenWidth <= 800 ? false : true">
        <el-table :data="fileList" stripe :height="innerHeight <= 400 ? 400 : innerHeight- 175" size="small"
            @row-click="tabClick" v-loading="loading" show-summary :row-class-name="tableRowClassName"
            @sort-change='sort' row-key="id">
            <el-table-column v-for="(item, index) in tableHeads" :key="index" :label="item.label" :fixed="item.fixed"
                v-cloak='item.hide' :prop="item.property" show-overflow-tooltip :sortable="item.sort">
            </el-table-column>
        </el-table>
    </div>
    <div class="br_con" v-loading="loading" v-else>
        <el-space wrap size="default" alignment="flex-start" :fill="screenWidth <= 800 ? true : false">
            <el-card style="width: 300px" v-for="(item, index) in fileList" :key="index">
                <el-descriptions direction="vertical" :column="2" size="small" border>
                    <el-descriptions-item v-for="(v, i) in tableHeads" :key="i" :label="v.label">
                        {{item[v.property]}}
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
            <el-card style="width: 300px;">
                <el-empty description="没有数据" />
            </el-card>
        </el-space>
    </div>
    <div style="height: 40px;padding: 0;margin: 0;">
        <el-footer height='40'>
            <el-pagination v-model:current-page="lookup.currentPage" background v-model:page-size="lookup.pageSize"
                hide-on-single-page layout="prev, pager, next, jumper " :total="lookup.total"
                @size-change="handleCurrentChange" @current-change="handleCurrentChange" />
        </el-footer>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        name: "CatTable",
        props: {
            fileList: Array,
            tableHead: Array,
            lookup: Object,
            tabname: String,
            loading: Boolean
        },
        computed: {
            // Vue计算属性
            ...mapState(['innerHeight', 'screenWidth']),
            tableHeads: function() {
                return this.tableHead.filter((item) => {
                    // 过滤隐藏数据
                    if (item.hide) {
                        return item
                    }
                })
            },
        },
        emits: ['tabname', 'currentPage', 'rowvl', ],
        methods: {
            sort(column) {
                // 排序
                if (column.order == 'descending') {
                    this.fileList.sort((a, b, v = column.prop) => {
                        return parseFloat(a[v]) - parseFloat(b[v]) - 0
                    })
                } else if (column.order == 'ascending') {
                    this.fileList.sort((a, b, v = column.prop) => {
                        return parseFloat(b[v]) - parseFloat(a[v]) - 0
                    })
                } else {
                    this.fileList.sort((a, b, v = 'index') => {
                        return parseFloat(b[v]) - parseFloat(a[v]) - 0
                    })
                }
            },
            handleCurrentChange(v) {
                this.$emit('currentPage', v)
            },
            tabClick(row, column, cell, event) {
                //将数据传递给父组件
                this.$emit('rowvl', row)
            },
            tableRowClassName({ // 把每一行的索引放进row 删除引用
                row,
                rowIndex
            }) {
                row.index = rowIndex
            },
        },
    }
</script>

<style scoped>
    .card-header {
        padding-left: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .el-footer {
        margin-top: 5px;
        margin-bottom: 5px;
    }
</style>